import axios from "axios";

export function fetch_payment_list() {
    return axios.get('/payment.json',)
        .then(function (response) {
            return response.data['paymentList']
        })
        .catch(function (error) {
            console.log(error);
        });
}