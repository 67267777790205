<template>
  <div>
    <v-tabs class="mt-4" v-model="tab" centered>
      <v-tab>VIP</v-tab>
      <v-tab>巴士会 SVIP🔥</v-tab>
    </v-tabs>
    <v-container class="wrapper mx-auto">
      <v-slide-x-transition>
        <v-alert
            prominent
            class="mx-3 pl-7 mb-7"
            border="left"
            colored-border
            type="error"
            elevation="2"
            tile
            v-if="unpaid_order.order_number"
        >
          <v-row align="center">
            <v-col class="grow">
              未完成订单：
              <span class="red--text mr-3 font-weight-bold">{{unpaid_order.svip === 1 ? '[巴士会SVIP] ' : ''}}{{
                  planList[unpaid_order.vip_id - 1].plan_name
                }}</span>
              如已完成付款请勿取消订单，等待1-3分钟，若仍未开通请在页面下方提交报告
            </v-col>
            <v-col
                cols="12"
                md="3"
                class="shrink mr-n5"
                style="transform: translateY(-1px)"
            >
              <v-btn
                  color="primary"
                  class="mr-3"
                  @click="orderNewDialog = true"
                  rounded
              >查看
              </v-btn>
              <v-btn
                  color="white"
                  @click="handleCancel(unpaid_order.order_number)"
                  rounded
              >取消订单
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-slide-x-transition>

      <v-slide-x-transition>
        <v-alert
            :color="vipLevel === 4 && plan_index === 3 ?  '#FF70AB' :'primary'"
            :icon="vipLevel === 4 && plan_index === 3 ? 'mdi-crown' : 'mdi-information'"
            dark
            border="left"
            class="mb-7"
            v-show="tab===1 && vipLevel > 0"
        >
          {{ vipLevel === 4 && plan_index === 3  && this.svip !== 1 ? '您是尊贵的终身VIP，支付差价￥170 即可加入巴士会畅享终身最佳权益（限时30天可抵扣差价升级）' : plan_index===3 ? '限量发售中，即刻拿下最佳体验！' : '购买后优先使用巴士会SVIP权益，未使用的VIP权益将在巴士会SVIP到期后继续生效' }}
        </v-alert>
      </v-slide-x-transition>

      <v-slide-group mandatory v-model="plan_index">
        <v-container>
          <v-row no-gutters>
            <v-col v-for="(plan, index) in planList" :key="index" cols="3" md="3">
              <v-slide-item light v-slot:default="{ active, toggle }">
                <v-hover v-slot:default="{ hover }">
                  <v-sheet
                      class="d-flex align-center PlanCardSingleRowCompact"
                      :class="[
                    active && !fetch_loading
                      ? 'PlanCardSingleRowCompact--active'
                      : '',
                    hover ? 'elevation-4' : 'sha-1',
                  ]"
                      height="250"
                      @click="toggle"
                      tile
                  >
                    <v-container
                        class="d-flex flex-column text-center"
                        v-if="!fetch_loading"
                    >
                      <div class="PlanCardSingleRowCompact__radio ml-1">
                      <span class="isvg loaded SVG-wrapper SVG-loaded">
                        <svg
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            class="SVG SVG--inline scale-24 c-bw-1"
                        >
                          <path
                              d="M13 5.496L11.571 4 6.47 9.342 4.43 7.205 3 8.701l3.47 3.632z"
                              fill="#fff"
                          />
                        </svg>
                      </span>
                      </div>
                      <div class="PlanCardSingleRowCompact__popup c-bw-1">
                        <span>{{ plan.popup_text }}{{tab === 1 ? 'PLUS' : ''}}</span>
                      </div>

                      <div class="PlanCardSingleRowCompact__body-upper-content">
                        <div>
                        <span class="subtitle-1 font-weight-bold" v-if="tab === 1">{{
                            plan.plan_name.replaceAll('计划','巴士会')
                          }}</span>

                          <span class="subtitle-1 font-weight-bold" v-else>{{
                            plan.plan_name
                          }}</span>
                        </div>
                        <div class="PlanCardSingleRowCompact__pricing mt-1 mb-3">
                        <span
                            class="
                            PlanCardSingleRowCompact__pricing--currency
                            font-weight-bold
                          "
                        >¥</span
                        >
                          <span class="display-2 font-weight-black">{{
                              plan.monthly_price
                            }}</span>
                          <div class="subtitle-2 mt-1">每月</div>
                        </div>

                        <v-chip small style="transform: translateY(7px)"
                        >节省{{ plan.discount_pct }}%
                        </v-chip>
                      </div>

                      <div class="PlanCardSingleRowCompact__description mt-4">
                        <del class="subtitle-2 red--text mr-2"
                        >CNY ¥{{ plan.discount_original_price }}
                        </del>
                        <span class="subtitle-2"
                        >CNY ¥{{
                            plan.discount_final_price + plan.plan_time
                          }}</span
                        >
                      </div>
                    </v-container>
                    <v-container v-else>
                      <v-row justify="center">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                            :size="50"
                            :width="4"
                        ></v-progress-circular>
                      </v-row>
                    </v-container>
                  </v-sheet>
                </v-hover>
              </v-slide-item>
            </v-col>
          </v-row>
        </v-container>
      </v-slide-group>

      <v-card class="mt-1 mx-3" v-if="!fetch_loading">
        <v-card-title>
          <span class="ma-1">订单信息</span>
        </v-card-title>
        <v-divider></v-divider>
        <div class="d-flex flex-row justify-space-between pa-4">
          <div class="d-flex flex-row align-center my-2">
            <v-img
                :src="vipImg"
                :lazy-src="vipImg"
                width="120px"
                height="100px"
                contain
                class="mt-3"
                :class="{
              'mr-1 ml-n4': $vuetify.breakpoint.xsOnly,
              'mr-5': $vuetify.breakpoint.smAndUp,
            }"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                      indeterminate
                      color="grey lighten-1"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="d-flex flex-column">
              <span class="title mb-2">{{tab === 1 ? '[巴士会SVIP] ' : ''}}{{ planList[plan_index].plan_name }}{{tab === 1 ? 'PLUS' : ''}}</span>
              <span class="block body-2 mb-1" v-show="tab === 1">
              <v-icon color="green" class="mr-2" small>mdi-check-circle</v-icon
              >包含VIP全部权益
            </span>
              <span class="block body-2 mb-1" v-show="tab === 1">
              <v-icon color="green" class="mr-2" small>mdi-check-circle</v-icon
              >视频在线播放
            </span>
              <span class="block body-2 mb-1" v-show="tab === 1">
              <v-icon color="green" class="mr-2" small>mdi-check-circle</v-icon
              >图包在线浏览
            </span>
              <span class="block body-2 mb-1" v-show="tab === 1">
              <v-icon color="green" class="mr-2" small>mdi-check-circle</v-icon
              >巴士会专属线路
            </span>
              <span class="block body-2 mb-1" v-show="tab === 1">
              <v-icon color="green" class="mr-2" small>mdi-check-circle</v-icon
              >优先资源求购权
            </span>
              <span class="block body-2 mb-1" v-show="tab === 1">
              <v-icon color="green" class="mr-2" small>mdi-check-circle</v-icon
              >独家资源优先解锁
            </span>
              <span class="block body-2 mb-1" v-show="tab === 1">
              <v-icon color="green" class="mr-2" small>mdi-check-circle</v-icon
              >巴士会专属评论图标
            </span>
              <span class="block body-2 mb-1">
              <v-icon color="green" class="mr-1" small>mdi-check-circle</v-icon>
              计划等级内所有类别{{ day }}下载
              <v-chip small style="position: relative; bottom: 1px"
              >{{tab === 1 ? unlockLimit*2 : unlockLimit}}次解锁/天</v-chip
              >
            </span>
              <span class="block body-2 mb-1">
              <v-icon color="green" class="mr-2" small>mdi-check-circle</v-icon
              >500,000+顶级优质资源
            </span>
              <span class="block body-2">
              <v-icon color="green" class="mr-2" small>mdi-check-circle</v-icon
              >绝佳安全性与隐私性
            </span>
            </div>
          </div>
          <div class="headline mt-2 d-none d-sm-flex">
            ¥{{ planList[plan_index].discount_original_price }} CNY
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="d-flex flex-row justify-space-between pa-5">
          <div class="d-flex flex-column">
            <div class="title mb-2" style="color: #f64f64">
              折扣
              <v-chip
                  class="mb-1 ml-1"
                  small
                  color="#f64f64"
                  label
                  style="transform: translateY(-1px)"
                  outlined
              >
                <v-icon small class="mr-1">mdi-{{ discount_icon }}</v-icon>
                {{ discount_name }}折扣
              </v-chip>
            </div>
            <div class="title mb-2" style="color: #f64f64" v-if="isEligibleForPriceAdjustment">
              差价抵扣
              <v-chip
                  class="mb-1 ml-1"
                  small
                  color="#f64f64"
                  label
                  style="transform: translateY(-1px)"
                  outlined
              >
                <v-icon small class="mr-1">mdi-sale</v-icon>
              限时30天
              </v-chip>
            </div>
            <div class="title">总费用</div>
          </div>
          <div>
            <div class="headline mb-2" style="color: #f64f64">
              - {{ Math.abs(planList[this.plan_index].discount_pct) }} %
            </div>
            <div class="headline mb-2" style="color: #f64f64" v-if="isEligibleForPriceAdjustment">
              - 198 CNY
            </div>
            <div class="headline">
              ¥{{discount_final_price}} CNY</div>
          </div>
        </v-card-actions>
      </v-card>
      <v-container class="mt-10" v-else style="transform: translateY(150px)">
        <v-row justify="center">
          <v-progress-circular
              indeterminate
              color="primary"
              :size="50"
              :width="4"
          ></v-progress-circular>
        </v-row>
      </v-container>

      <v-item-group mandatory v-model="payment_index" v-if="!fetch_loading">
        <v-container>
          <v-row>
            <v-col
                v-for="(payment, index) in paymentList"
                :key="index"
                cols="12"
                md="3"
            >
              <v-item light v-slot:default="{ active, toggle }">
                <v-hover v-slot:default="{ hover }">
                  <v-sheet
                      class="d-flex align-center PaymentsButtonsSection__card"
                      :class="[
                    active ? 'PaymentsButtonsSection--active' : '',
                    hover ? 'elevation-5' : 'sha-1',
                  ]"
                      height="70"
                      @click="toggle"
                  >
                    <v-container class="d-flex flex-row justify-space-between">
                      <div>
                        <div
                            class="PaymentsButtonsSection__radio d-inline-block"
                        ></div>
                        <!-- <span class="ml-3">{{payment.paymentText}}</span> -->
                      </div>
                      <div
                          class="d-flex align-center justify-end"
                          :class="index == 2 ? 'mt-0' : 'mt-1'"
                      >
                        <v-img
                            :src="payment.paymentImg"
                            :width="payment.paymentImgWidth"
                            contain
                        ></v-img>
                      </div>
                    </v-container>
                  </v-sheet>
                </v-hover>
              </v-item>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>

      <!-- 支付按钮 -->
      <v-card class="mt-1 mb-8 mx-3" v-if="!fetch_loading">
        <v-container class="py-4 px-8">
          <div
              :class="{
            'd-flex justify-space-between': !$vuetify.breakpoint.mobile,
          }"
              v-if="paymentList[payment_index].availability === true && payment_index < 3"
          >
            <v-btn
                text
                class="red--text"
                @click="paymentDialog = true"
                v-if="!$vuetify.breakpoint.mobile"
            >
              <v-icon left>mdi-alert</v-icon>
              报告
            </v-btn>



            <v-btn
                :color="
              payment_index == 0 || payment_index == 1 ? '#00AAEE' : 'green'
            "
                @click="handleOrder"
                :loading="overlay"
                :disabled="overlay || paymentList[payment_index].availability === false"
                dark
                :block="$vuetify.breakpoint.mobile"
            >
              提交订单
              <div v-if="$vuetify.breakpoint.mdAndUp">
                <v-img
                    src="https://static.moegoat.com/images/alipay-white.svg"
                    width="50"
                    contain
                    class="mb-1 ml-2"
                    v-if="payment_index === 0 || payment_index === 1"
                ></v-img>
                <v-img
                    src="https://static.moegoat.com/images/payments/1/wechat-white.svg"
                    width="20"
                    contain
                    class="ml-2"
                    v-else
                ></v-img>
              </div>
            </v-btn>

            <v-btn
                text
                class="red--text mt-3"
                @click="paymentDialog = true"
                block
                v-if="$vuetify.breakpoint.mobile"
            >
              <v-icon left>mdi-alert</v-icon>
              报告
            </v-btn>
          </div>


          <!--暂停使用微信-->

          <template  v-else-if="paymentList[payment_index].availability === false">
            <div class="d-flex justify-center">
              <v-icon color="red" class="mr-2">mdi-alert-circle</v-icon>
              <span>{{ paymentList[payment_index].paymentText }}通道暂停使用，请使用支付宝-2</span>
            </div>
            <v-btn @click="payment_index = 1" block class="mt-4" color="primary">切换通道</v-btn>
          </template>


          <div class="d-flex justify-center" v-else>
            <v-icon color="red" class="mr-2">mdi-alert-circle</v-icon>
            <span
            >请选择其他付款方式。
            您的账户注册地或使用地暂不支持此支付方式，请联系在线客服</span
            >
          </div>
        </v-container>
      </v-card>

      <!-- 订单创建loading -->
      <v-overlay :value="overlay" color="#151922">
        <v-row
            class="fill-height ma-0 d-flex flex-column pb-5"
            align="center"
            justify="center"
        >
          <v-progress-circular
              indeterminate
              color="white"
              :size="50"
              :width="4"
              class="mb-4"
          ></v-progress-circular>
          <span class="title font-weight-black my-2">正在处理您的订单...</span>
          <span class="caption">请稍等几秒钟。请不要关闭本页面。</span>
        </v-row>
      </v-overlay>

      <!-- 开通成功模态框 -->
      <v-dialog
          v-model="success_dialog"
          max-width="350"
          transition="scale-transition"
      >
        <v-card class="d-flex flex-column px-10 pt-3 pb-6 text-center">
          <v-img
              width="180px"
              height="150px"
              contain
              class="mx-auto mt-5"
              :src="planList[success_index - 1].vip_img"
          ></v-img>
          <div class="mt-12 mb-5 title">
            <v-icon color="green" class="mr-1">mdi-check-circle</v-icon>
            {{tab === 1 ? '[巴士会SVIP]' : ''}}{{ planList[success_index - 1].plan_name }}开通成功!
          </div>
          <div class="mt-5">
            <v-btn
                color="primary"
                block
                @click="
              success_dialog = false;
              fetchUnpaidOrder();
              $router.push('/');
            "
            >启程
            </v-btn>
          </div>
        </v-card>
      </v-dialog>

      <!-- 新支付反馈 -->
      <v-dialog v-model="paymentDialog" max-width="480">
        <v-card>
          <v-toolbar color="primary" dark height="54px"
          >⚠️ 已付款但未开通会员？
          </v-toolbar>
          <v-card-text>
            <div v-if="!order_number" class="pt-10 text-center">
              <template v-if="hasUnpaidOrder">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                    class="mt-5"
                ></v-progress-circular>
                <p class="mt-10 body-1 font-weight-light">获取中...</p>
              </template>
              <template v-else>
                <div class="mt-3 mb-6">
                  <p>🧸 oh！没有进行中的订单 Σ( ° △ °|||)︴</p>
                  <p>
                    可联系在线客服或发邮件至 loibus.service@gmail.com 获取帮助
                  </p>
                </div>
              </template>
            </div>

            <div class="pt-10 pb-1" v-else>
              <p class="subtitle-1">
                未开通订单校验码:
                <v-chip>{{ order_number }}</v-chip>
              </p>
              <v-btn @click="handlePullFailed" color="info" block class="mt-3">联络在线支付处理专员</v-btn>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">其他问题？</v-btn>
              </template>
              <v-list dense>
                <v-list-item
                    v-for="problem in problems_list"
                    :key="problem.id"
                    @click="handleOtherProblems(problem.id)"
                >
                  <v-list-item-title>{{ problem.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>
            <!--          <v-btn-->
            <!--            v-if="hasUnpaidOrder"-->
            <!--            color="primary"-->
            <!--            text-->
            <!--            @click.stop="handleCheckAlipayNum"-->
            <!--            :loading="check_alipay_orderNum_loading"-->
            <!--            :disabled="-->
            <!--              check_alipay_orderNum_disabled || check_alipay_orderNum_loading-->
            <!--            "-->
            <!--            >{{ check_alipay_orderNum_text }}</v-btn-->
            <!--          >-->

            <v-btn text @click="paymentDialog = false">关闭</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- messageDialog -->
      <v-dialog v-model="messageDialog" max-width="380" persistent>
        <v-card>
          <v-card-title class="text-h6">
            {{ messageTitle }}
          </v-card-title>

          <v-card-text>
            {{ messageContent }}
          </v-card-text>



          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="messageDialog = false">ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 新支付反馈 -->

      <!-- 新订单开通确认模态-->
      <v-dialog v-model="orderNewDialog" persistent width="350">
        <v-card>
          <v-card-text class="pa-5">
            <div class="mt-1 mb-2">校验码：</div>
            <div class="mb-3">
              <v-chip>{{ order_number || "获取中..." }}</v-chip>
            </div>
            <v-btn v-if="orderUrl && paymentList[payment_index].continuePayment === true" @click="openPayUrl" rounded
                   color="primary"
            >跳转支付
              <v-icon small>mdi-arrow-top-right-thick</v-icon>
              （若未弹出支付则多试几次）
            </v-btn>
            <div class="mb-2 mt-6">⏳ {{unpaid_order.svip === 1 ? '[巴士会SVIP] ' : ''}}等待付款结果中...</div>
            <v-progress-linear
                indeterminate
                color="primary"
                class="mt-6"
            ></v-progress-linear>
          </v-card-text>
          <v-card-actions>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">无法开通？</v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="handlePullFailed">
                  <v-list-item-title>👩‍💻 在线客服</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-for="problem in problems_list"
                    :key="problem.id"
                    @click="handleOtherProblems(problem.id)"
                >
                  <v-list-item-title>{{ problem.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn
                color="grey"
                text
                @click="
              orderNewDialog = false;
              fetchUnpaidOrder();
            "
            >关闭
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="handleConfirm"
                :loading="confirm_loading"
                :disabled="confirm_loading"
            >我已支付
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!--  rec alipay  -->
      <v-dialog v-model="recAlipayDialog" width="300" persistent>
        <v-card>
          <v-card-title class="text-h6 blue white--text">
            ⚠️ 支付提醒
          </v-card-title>

          <v-card-text class="mt-3">
            推荐使用<span class="font-weight-bold">支付宝-1</span>支付
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <!--          <v-btn-->
            <!--              color="grey"-->
            <!--              text-->
            <!--              @click="recAlipayDialog = false;"-->
            <!--          >-->
            <!--            继续支付-->
            <!--          </v-btn>-->
            <v-btn
                color="primary"
                text
                @click="
              recAlipayDialog = false;
              payment_index = 0;
            "
            >
              使用支付宝
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import {getQueryVariable} from "@/utils";
import {
  createOrder,
  cancelOrder,
  confirmOrder,
  getUnpaidOrder,
  check_alipay_orderNum
} from "@/api/order";
import {getVips} from "@/api/vip";

import msg from "@/utils/snackbar.js"; //消息提示组件

import {fetch_payment_list} from "@/api/payment";
import {getVipLevel} from "@/utils/auth";
import  {getIsSvip} from '@/utils/auth'


export default {
  name: "order",
  data() {
    return {
      svip:0,
      vipLevel:0,
      tab:0,
      orderNewDialog: false,
      orderType: "alipay",
      orderUrl: "",

      recAlipayDialog: false,

      check_alipay_orderNum_disabled: false,
      check_alipay_orderNum_text: "检查",
      timer_check_alipay_orderNum_count: 60,
      timer_check_alipay_orderNum: null,


      price_data:null,

      hasUnpaidOrder: false,
      problems_list: [
        {
          id: 1,
          title: "跳转后网页自动关闭",
          message:
              "很抱歉，可能是支付服务器临时出现异常，您可以多次尝试点击`跳转支付`直到成功拉起支付，若仍然无效，可以尝试取消订单后更换支付通道重新提交或联系在线客服。",
        },
        {
          id: 2,
          title: "跳转后支付的网址打不开/链接失败",
          message:
              "很抱歉，可能是支付服务器临时出现异常，请更换网络环境，切换VPN节点，并点击`跳转支付`再次尝试，若仍然无效，可以尝试取消订单后更换支付通道重新提交或联系在线客服。",
        },
        {
          id: 3,
          title: "已付款但未成功开通",
          message:
              "很抱歉，可能是支付服务器临时出现异常，请勿取消付款后未开通的订单，并联系在线客服，发送 [订单校验码] + [交易订单号] + [支付详情截图]，将在审核后开通，也可提交至邮箱 loibus.service@gmail.com 获取帮助。",
        },
        {
          id: 4,
          title: "支付界面异常",
          message:
              "很抱歉，可能是您使用了【不受支持】的旧版浏览器导致的，不幸的是，LOIBUS不支持Internet Explorer浏览器及Safari 10+以下版本的浏览器，多数移动设备自带浏览器无法正常访问，您可能会遇到各种错误（图片显示异常/资源无法加载等），建议使用最新Chromium内核的浏览器访问以获得最佳体验（推荐chrome浏览器）",
        },
        {
          id: 5,
          title: "扫码后无法支付",
          message:
              "很抱歉，可能是您的账户注册地或使用地暂不支持此支付方式，请联系在线客服并发送问题截图，也可提交至邮箱 loibus.service@gmail.com 获取帮助。",
        },
        {
          id: 6,
          title: "其他",
          message:
              "请联系在线客服说明您遇到的问题，也可提交至 loibus.service@gmail.com 获取帮助",
        },
      ],
      messageTitle: "",
      messageContent: "",
      messageDialog: false,
      paymentDialog: false,
      alipay_orderNum: "",
      alipay_orderNum_rules: [
        (value) =>
            value != this.order_number ||
            "输入错误，您输入的是[校验码]，请填写[交易订单号]",
        (value) =>
            !!value ||
            "支付宝 > 我的 > 账单 > 账单详情 > 更多 > 长按复制 [订单号]",
        (value) =>
            (value && value.length === 28) ||
            "请检查是否输入有误，交易订单号为28位数字",
        (value) =>
            /(^[1-9]\d*$)/.test(value) || "格式错误，交易订单号为28位数字",
      ],
      check_alipay_orderNum_loading: false,

      plan_index: 0,
      payment_index: 0,
      payment_color: "",
      payment_img: "",

      price: 0,

      overlay: false,
      fetch_loading: true,
      confirm_loading: false,

      success_dialog: false,
      code_dialog: false,

      order_number: "",
      paycheckTimer: null,
      success_index: 1,
      unpaid_order: {
        order_number: "",
        payment: 0,
        vip_id: 0,
        svip:0
      },

      pay_dialog: false,
      pay_stepper: 1,
      pay_qrcode: "",
      payTips_dialog: false,
      alipayTips_phone: false,
      wxPayTips_phone: false,
      phone_tips_img: "",

      waitNext_countdown: 10,
      waitNext_timer: null,
      waitNext_text: "请确认已复制校验码(10)",
      waitNext_disabled: false,

      payCheck_timer: null,
      payCheck_countdown: 60,
      payCheck_disabled: false,
      payCheck_text: "我已付款",

      // 反馈
      feedback_list: [
        {
          id: 8,
          text: "付款完成但会员未开通成功",
          placeholder:
              "【请在此处填写付款校验码】若未在备注填写校验码付款的用户请提交反馈后耐心等待，可关闭本页面但请勿取消订单（可联系在线客服后发送支付截图，我们将在核实后尽快开通）",
        },
        {
          id: 9,
          text: "无法提交订单",
          placeholder:
              "【请在此处填写原因】使用uc/夸克浏览器用户无法正常显示付款码请换用其他浏览器(推荐使用Chrome)，或请说明遇到的其他具体情况与出现的提示...",
        },
        {
          id: 10,
          text: "其他",
          placeholder:
              "【请在此处填写您遇到的问题】需求或疑问也可提交至 loibus.service@gmail.com （也联系在线客服）",
        },
      ],

      planList: [
        {
          popup_text: "初体验",
          vip_img: "https://static.moegoat.com/images/vip/v1.png",
          plan_name: "1个月计划",
          plan_days: "30天内",
          plan_time: "每月",
          plan_unlockLimit: 15,
          monthly_price: 0,
          discount_original_price: 0,
          discount_final_price: 0,
          discount_pct: 0,
        },
        {
          popup_text: "进阶司机",
          vip_img: "https://static.moegoat.com/images/vip/v2.png",
          plan_name: "3个月计划",
          plan_days: "90天内",
          plan_time: "每季度",
          plan_unlockLimit: 25,
          monthly_price: 0,
          discount_original_price: 0,
          discount_final_price: 0,
          discount_pct: 0,
        },
        {
          popup_text: "专属优惠",
          vip_img: "https://static.moegoat.com/images/vip/v3.png",
          plan_name: "1年计划",
          plan_days: "365天内",
          plan_time: "每年",
          plan_unlockLimit: 40,
          monthly_price: 0,
          discount_original_price: 0,
          discount_final_price: 0,
          discount_pct: 0,
        },
        {
          popup_text: "限量发售",
          vip_img: "https://static.moegoat.com/images/vip/v4.png",
          plan_name: "终身计划",
          plan_days: "终身",
          plan_time: "终身",
          plan_unlockLimit: 60,
          monthly_price: 0,
          discount_original_price: 0,
          discount_final_price: 0,
          discount_pct: 0,
        },
      ],
      paymentList: [
        {
          paymentText: "支付宝-1",
          paymentImg:
              "https://static.moegoat.com/images/payments/alipay_icon_1.png",
          paymentImgWidth: 90,
          availability: false,
          continuePayment: true,
          channel_id:6,
        },
        {
          paymentText: "支付宝-2",
          paymentImg:
              "https://static.moegoat.com/images/payments/alipay_icon_2.png",
          paymentImgWidth: 90,
          availability: true,
          continuePayment: true,
          channel_id:3,
        },
        {
          paymentText: "微信支付",
          paymentImg: "https://static.moegoat.com/images/payments/1/wechat.png",
          paymentImgWidth: 92,
          availability: false,
          continuePayment: true,
          channel_id:6,
        },
        {
          paymentText: "Paypal",
          paymentImg: "https://static.moegoat.com/images/payments/paypal.png",
          paymentImgWidth: 80,
          availability: true,
          continuePayment: false,
          channel_id:0,
        },
        // {
        //   paymentText: "Debit Card",
        //   paymentImg: "https://static.moegoat.com/images/payments/molpay.png",
        //   paymentImgWidth: 90,
        // },
      ],
    };
  },
  methods: {
    createPayUrl_qw(order_number) {
      this.orderUrl =
          "https://api.moegoat.com/qwpay/paying.php?type=" +
          this.orderType +
          "&loi_vip=" +
          this.vipName +
          "&loi_money=" +
          this.discount_final_price +
          "&loi_trade_no=" +
          order_number +
          "&jplink=" +
          window.location.host +
          "&t=" +
          Date.now();
    },

    createPayUrl_qitapay(order_number) {
      this.orderUrl =
          "https://api.moegoat.com/qtpay/paying.php?type=" +
          this.orderType +
          "&loi_vip=" +
          this.vipName +
          "&loi_money=" +
          this.discount_final_price +
          "&loi_trade_no=" +
          order_number +
          "&jplink=" +
          window.location.host +
          "&t=" +
          Date.now();
    },


    createPayUrl(order_number) {
      this.orderUrl =
          "https://api.moegoat.com/loipay/paying.php?type=" +
          this.orderType +
          "&loi_vip=" +
          this.vipName +
          "&loi_money=" +
          this.discount_final_price +
          "&loi_trade_no=" +
          order_number +
          "&jplink=" +
          window.location.host +
          "&t=" +
          Date.now();
    },

    openPayUrl() {
      // console.log(this.orderUrl);
      window.open(this.orderUrl, "_blank");
    },

    handlePullFailed() {
      alert('若已付款未开通会员，请向在线客服发送：已付款未开通， [订单校验码] + [交易订单号] + [支付详情截图]，将在审核后开通，也可提交至邮箱 loibus.service@gmail.com 获取帮助。')

      window.open('https://chatting.page/t9ac8zwwowzq4q2todl6ntrw6m7wpcsu');
    },

    async handleOrder() {
      if (this.discount_final_price === 0){
        return msg(
            "下单失败，请刷新页面重试 o(≧口≦)o",
            "error",
            "alert-circle",
            false
        );
      }

      this.overlay = true;
      this.clearPaycheckTimer();
      let price = this.discount_final_price;
      this.price = price;
      let order_info = {
        price: price,
        vip_id: this.plan_index + 1,
        payments: this.payment_index,
        channel_id:this.paymentList[this.payment_index].channel_id,
        svip:this.tab
      };

      // console.log(order_info)

      try {
        // 创建订单
        let response = await createOrder(order_info);

        // 获取订单号
        let order_num = response.data;
        this.order_number = order_num;

        this.hasUnpaidOrder = true;

        if (this.payment_index < 2) {
          //支付宝
          this.orderType = "alipay";
        } else if (this.payment_index === 2) {
          // 微信
          this.orderType = "wxpay";
        }

        //创建支付链接

        if (this.payment_index === 0 || this.payment_index === 2) {
          //支付宝-1-齐天支付
          this.createPayUrl_qitapay(order_num);

        } else if (this.payment_index === 1) {
          //支付宝-2-天使支付
          this.createPayUrl(order_num);

        }else {
          //暂停使用
          this.overlay = false;
          return msg('暂停使用')
        }

        //打开支付链接
        this.openPayUrl();

        //关闭加载模态 ·
        this.overlay = false;
        //开启支付弹窗
        this.orderNewDialog = true;

        //保持检查支付状态
        this.paycheckTimer = setInterval(() => {
          this.checkPayStatus(this.order_number);
        }, 10000);
      } catch (error) {
        msg(error.err_msg, "error", "alert-circle", false);
      }
      this.overlay = false;
    },
    async handleCancel(order_number) {
      let cancelConfirm = confirm(
          "❌ 若已付款，请先联系在线客服发送 [订单校验码] + [交易订单号] + [支付详情截图]，也可提交至邮箱 loibus.service@gmail.com 获取帮助。取消后本订单将无法开通，确定取消吗?"
      );
      if (cancelConfirm) {
        this.clearPaycheckTimer();
        try {
          let response = await cancelOrder({
            order_number: order_number,
          });
          msg(response.data, "warning", "close-circle");

          this.unpaid_order = {
            order_number: "",
            payment: 0,
            vip_id: 0,
            svip:0
          };
        } catch (error) {
          msg("订单取消失败!", "error", "alert-circle", false);
        }
        this.pay_dialog = false;
      }
    },
    async handleConfirm() {
      this.confirm_loading = true;
      try {
        let response = await confirmOrder({
          order_number: this.order_number,
        });
        this.success_index = response.data;
        this.pay_dialog = false;
        this.alipayTips_phone = false;
        this.wxPayTips_phone = false;
        this.paymentDialog = false;
        this.orderNewDialog = false;
        this.success_dialog = true;
        this.hasUnpaidOrder = false;

        //显示发布页地址
        this.showMessage(
            "🔗 发布页地址",
            "已加入会员，请保存发布页 loix.cc 获取最新入口"
        );

        this.unpaid_order = {
          order_number: "",
          payment: 0,
          vip_id: 0,
          svip:0
        };
        this.clearPaycheckTimer();
      } catch (error) {
        msg(error.err_msg, "error", "alert-circle", false);
      }
      this.confirm_loading = false;
    },
    async checkPayStatus(order_number) {
      try {
        let response = await confirmOrder({
          order_number: order_number,
        });
        this.success_index = response.data;
        this.pay_dialog = false;
        this.alipayTips_phone = false;
        this.wxPayTips_phone = false;
        this.paymentDialog = false;
        this.orderNewDialog = false;
        this.success_dialog = true;
        this.hasUnpaidOrder = false;

        //显示发布页地址
        this.showMessage(
            "🔗 发布页地址",
            "已加入会员，请保存发布页 loix.cc 获取最新入口"
        );

        this.unpaid_order = {
          order_number: "",
          payment: 0,
          vip_id: 0,
          svip:0
        };
        this.clearPaycheckTimer();
      } catch (error) {
        ("");
      }
    },
    async fetchVipPrice() {
      let {data} = await getVips();

      this.price_data = data;

      this.setVipPrice('vip')

      this.fetch_loading = false;
    },
    setVipPrice(type) {
      // 根据传入的参数决定使用的价格字段
      let priceField = type === 'vip' ? 'price' : 'svip_price';

      // 计算原价/discount_original_price
      this.planList[0].discount_original_price = this.price_data[0][priceField] + 20;
      this.planList[1].discount_original_price = this.planList[0].discount_original_price * 3;
      this.planList[2].discount_original_price = 200;
      this.planList[3].discount_original_price = 300;

      // 计算每月价格/monthly_price
      this.planList[0].monthly_price = this.price_data[0][priceField];
      this.planList[1].monthly_price = (this.price_data[1][priceField] / 3).toFixed(0);
      this.planList[2].monthly_price = (this.price_data[2][priceField] / 12).toFixed(0);
      this.planList[3].monthly_price = 0;

      // 获取现价/discount_final_price
      for (let i = 0; i < this.planList.length; i++) {
        this.planList[i].discount_final_price = this.price_data[i][priceField];

        //计算降价百分比/discount_pct
        this.planList[i].discount_pct = (
            ((this.planList[i].discount_original_price - this.planList[i].discount_final_price) /
                this.planList[i].discount_original_price) * 100
        ).toFixed(0);
      }
    },
    async fetchUnpaidOrder() {
      let response = await getUnpaidOrder();

      //存在未付款订单
      if (response) {
        this.unpaid_order = response;
        this.order_number = this.unpaid_order.order_number;
        this.payment_index = this.unpaid_order.payment;
        this.price =
            this.planList[this.unpaid_order.vip_id - 1].discount_final_price;

        // window.console.log(this.unpaid_order);
        if (this.paycheckTimer == null) {
          this.paycheckTimer = setInterval(() => {
            this.checkPayStatus(this.unpaid_order.order_number);
          }, 10000);
        }

        this.hasUnpaidOrder = true;
      }
    },
    handleUnpaidOrder() {
      this.clearPaycheckTimer();
      this.plan_index = this.unpaid_order.vip_id - 1;

      this.payment_index = this.unpaid_order.payment;

      if (this.unpaid_order.payment == 0) {
        //支付宝
        this.payment_color = "#00AAEE";
        this.payment_img =
            "https://static.moegoat.com/images/payments/0/alipay.png";
        this.pay_qrcode = `https://static.moegoat.com/images/payments/0/alipay_collect_v2.png`;
      } else if (this.unpaid_order.payment == 1) {
        // 微信
        this.payment_color = "green";
        this.payment_img =
            "https://static.moegoat.com/images/payments/1/wechat.png";
        this.pay_qrcode = `https://static.moegoat.com/images/payments/1/wxpay_collect.png`;
      }

      //开启支付弹窗
      this.openPayDialog();

      this.paycheckTimer = setInterval(() => {
        this.checkPayStatus(this.unpaid_order.order_number);
      }, 10000);
    },
    clearPaycheckTimer() {
      //清除定时器
      clearInterval(this.paycheckTimer);
      this.paycheckTimer = null;
    },
    onCopy: function () {
      msg("已复制，请在付款备注中填入此校验码", "success", "check", false);
    },
    onError: function () {
      msg("复制失败，请手动复制", "error", "alert-circle", false);
    },
    openPayDialog() {
      clearInterval(this.waitNext_timer);
      this.waitNext_timer = null;
      this.waitNext_disabled = true;
      this.waitNext_countdown = 10;
      this.waitNext_text = `请确认已复制校验码(${this.waitNext_countdown})`;
      this.pay_dialog = true;
      this.waitNext_timer = setInterval(() => {
        this.waitNext_text = `请确认已复制校验码(${this.waitNext_countdown})`;
        this.waitNext_countdown--;
        if (this.waitNext_countdown < 1) {
          clearInterval(this.waitNext_timer);
          this.waitNext_timer = null;
          this.waitNext_disabled = false;
          this.waitNext_text = "我已复制校验码";
        }
      }, 1000);
    },
    handleReadedTips() {
      this.payTips_dialog = false;
      this.payCheck_timer = setInterval(() => {
        this.payCheck_text = `等待支付结果中(${this.payCheck_countdown})`;
        this.payCheck_countdown--;
        if (this.payCheck_countdown < 1) {
          clearInterval(this.payCheck_timer);
          this.payCheck_timer = null;
          this.payCheck_disabled = false;
          this.payCheck_text = "我已付款";
        }
      }, 1000);
    },
    handleNext() {
      clearInterval(this.payCheck_timer);
      this.payCheck_timer = null;
      this.payCheck_disabled = true;
      this.payCheck_countdown = this.payment_index == 0 ? 60 : 60;
      this.payCheck_text = `等待支付结果中(${this.payCheck_countdown})`;
      this.pay_stepper = 2;
      this.payTips_dialog = true;

      if (this.payment_index == 0) {
        this.alipayTips_phone = true;
      } else {
        this.wxPayTips_phone = true;
      }
    },
    handleClosePayDialog() {
      this.pay_dialog = false;
      this.fetchUnpaidOrder();
      this.pay_stepper = 1;
      this.alipayTips_phone = false;
      this.wxPayTips_phone = false;
    },

    handleOtherProblems(id) {
      this.showMessage(
          this.problems_list[id - 1].title,
          this.problems_list[id - 1].message
      );
    },

    showMessage(title, messgae) {
      this.messageTitle = title;
      this.messageContent = messgae;
      this.messageDialog = true;
    },

    async handleCheckAlipayNum() {
      if (!this.$refs.alipay_orderNum_form.validate()) {
        return msg(
            "请正确填写交易订单号 (°ー°〃)",
            "error",
            "alert-circle",
            false
        );
      }

      this.check_alipay_orderNum_loading = true;

      let res = await check_alipay_orderNum({
        alipay_orderNum: this.alipay_orderNum,
        order_num: this.order_number,
      });

      if (res === "no match") {
        msg(
            "检查失败，订单号不存在或错误  o(≧口≦)o",
            "error",
            "alert-circle",
            false
        );
      } else if (res === "Success") {
        this.pay_dialog = false;
        this.paymentDialog = false;
        this.alipayTips_phone = false;
        this.wxPayTips_phone = false;
        this.hasUnpaidOrder = false;

        msg(
            this.planList[this.success_index - 1].plan_name +
            "开通成功啦！ (*/ω＼*)",
            "success",
            "check",
            false
        );
      } else {
        msg("未知错误，请联系在线客服", "error", "alert-circle", false);
      }

      this.check_alipay_orderNum_loading = false;

      //发送后禁用
      this.check_alipay_orderNum_disabled = true;
      this.timer_check_alipay_orderNum_count = 60;
      this.check_alipay_orderNum_text = `${this.timer_check_alipay_orderNum_count}秒后可检查`;

      // 限制发送间隔
      this.timer_check_alipay_orderNum = setInterval(() => {
        this.timer_check_alipay_orderNum_count--;
        this.check_alipay_orderNum_text = `${this.timer_check_alipay_orderNum_count}秒后可检查`;
        if (this.timer_check_alipay_orderNum_count <= 0) {
          clearInterval(this.timer_check_alipay_orderNum);
          this.timer_check_alipay_orderNum = null;
          this.check_alipay_orderNum_disabled = false;
          this.check_alipay_orderNum_text = "检查";
        }
      }, 1000);

      // console.log(res);
    },
    getRandom(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  },
  computed: {
    discount_final_price() {
      let final_price = this.planList[this.plan_index].discount_final_price
      return this.isEligibleForPriceAdjustment ? final_price-198 : final_price;
    },
    day() {
      return this.planList[this.plan_index].plan_days;
    },
    unlockLimit() {
      return this.planList[this.plan_index].plan_unlockLimit;
    },
    vipImg() {
      return this.planList[this.plan_index].vip_img;
    },
    discount_name() {
      const discounts = ["体验", "绝佳", "限时", "限量"];
      return discounts[this.plan_index];
    },
    discount_icon() {
      const discounts = ["creation", "current-ac", "fire", "crown"];
      return discounts[this.plan_index];
    },
    vipName() {
      const lv = ["月付", "季付", "年付", "终身"];

      if (this.tab === 1){
        return lv[this.plan_index] + "SVIP";
      }else {
        return lv[this.plan_index];
      }
    },
    // 是否符合补差价升级条件
    isEligibleForPriceAdjustment() {
      return this.tab === 1 && this.vipLevel === 4 && this.plan_index === 3 && this.svip !== 1;
    }
  },
  async created() {
    // console.log(window.location.host);

    await this.fetchVipPrice();
    this.fetchUnpaidOrder();
    this.plan_index = Number(getQueryVariable("plan")) || 0;

    this.vipLevel = Number(getVipLevel());
    this.svip = Number(getIsSvip());
    this.tab = Number(getQueryVariable("svip")) || 0;

    //获取支付通道
    this.paymentList = await fetch_payment_list()

    //随机支付通道

    if (this.paymentList[0].availability === false){
      this.payment_index = 1
    }else {
      const payment = [0,1, 1, 0, 1, 0]
      this.payment_index = payment[this.getRandom(0, payment.length - 1)]

    }


    // this.payment_index = 0
  },
  watch:{
    tab(val){
      if (val === 0){
        this.setVipPrice('vip')
      }else {
        this.setVipPrice('svip')
      }
    }
  }
  // watch: {
  //   payment_index(val) {
  //     if (val === 2 || val === 1) {
  //       this.recAlipayDialog = true;
  //     }
  //   },
  // },
};
</script>

<style scoped>
.wrapper {
  max-width: 1170px;
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.PlanCardSingleRowCompact {
  cursor: pointer;
  border: 3px solid transparent;
  position: relative;
  transition: box-shadow 0.25s ease-out, border-color 0.25s ease-out;
}

.PlanCardSingleRowCompact--active {
  border-color: #03a9f4;
}

.PlanCardSingleRowCompact--active .PlanCardSingleRowCompact__radio {
  border-color: #03a9f4;
  background-color: #03a9f4;
}

.PlanCardSingleRowCompact__radio {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #e8e8e9;
  border-radius: 50%;
  transition: border-color 0.25s ease-out, background-color 0.15s ease-out;
}

.PlanCardSingleRowCompact--active .PlanCardSingleRowCompact__popup {
  background-color: #03a9f4;
}

.PlanCardSingleRowCompact__popup {
  display: table;
  background-color: #f64f64;
  border-radius: 25px;
  transition: background-color 0.25s ease-out;
  font-size: 13px;
  line-height: 1.428571429;
  padding: 6px 20px;
  margin: 0 auto;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  -webkit-transform: translateY(-100%);
  transform: translateY(-105%);
}

.PlanCardSingleRowCompact--active .PlanCardSingleRowCompact__popup:after {
  border-color: #03a9f4 transparent;
}

.PlanCardSingleRowCompact__popup:after {
  content: "";
  position: absolute;
  border-color: #f64f64 transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  width: 0;
  left: 50%;
  bottom: 0;
  -webkit-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
  transition: border-color 0.25s ease-out;
}

.sha-1,
.sha-1-hover:hover {
  box-shadow: 0 0 0 1px rgba(56, 60, 67, 0.05),
  0 1px 3px 0 rgba(56, 60, 67, 0.15);
}

.PlanCardSingleRowCompact--active
.PlanCardSingleRowCompact__radio
.SVG--inline {
  opacity: 1;
}

.PlanCardSingleRowCompact__radio .SVG--inline {
  position: absolute;
  opacity: 0;
  height: 16px;
  left: 0;
  top: 0;
  transition: opacity 0.25s ease-out;
}

.SVG-wrapper .SVG--inline {
  width: auto;
}

.SVG-wrapper .SVG--inline {
  display: inline-block;
  vertical-align: sub;
}

svg:not(:root) {
  overflow: hidden;
}

.scale-24 {
  height: 24px;
}

.c-bw-1 {
  color: #fff;
}

.PlanCardSingleRowCompact__body-upper-content {
  flex: 1 1 auto;
  transform: translateY(-15px);
}

.PlanCardSingleRowCompact__pricing--currency {
  font-size: 14px;
  vertical-align: top;
  position: relative;
  font-weight: 500;
  line-height: 1.5;
}

.PlanCardSingleRowCompact__description {
  font-size: 12px;
  margin-bottom: -3px;
}

.PaymentsButtonsSection__card {
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.25s ease-out;
  padding: 30px 20px;
}

.PaymentsButtonsSection__radio {
  flex: none;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 1px solid #b9babd;
  position: relative;
  top: 4px;
  background-color: #fff;
  transition: background-color 0.25s ease-out, border-color 0.5s ease-out;
}

.PaymentsButtonsSection--active .PaymentsButtonsSection__radio {
  background-color: #03a9f4;
  border-color: #03a9f4;
}

.PaymentsButtonsSection--active .PaymentsButtonsSection__radio:after {
  opacity: 1;
}

.PaymentsButtonsSection__radio:after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: #fff;
  left: 50%;
  border-radius: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.25s ease-out;
}

.pay-border {
  width: 170px;
  height: 170px;
  border: 1px solid #dedede;
  padding: 5px;
}

.payment_example_pc {
  position: fixed;
  top: 7vh;
  left: 15%;
  z-index: 201;
}
</style>