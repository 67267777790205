import request from '@/utils/request'

export function createOrder(data) {
    return request({
        url: 'user/orderV2',
        method: 'post',
        data
    })
}

export function cancelOrder(data) {
    return request({
        url: 'user/order/cancel',
        method: "post",
        data
    })
}

export function confirmOrder(data) {
    return request({
        url: 'user/order/confirm',
        method: "post",
        data
    })
}

export function getUnpaidOrder(data) {
    return request({
        url: 'user/order/unpaidorder',
        method: "post",
        data
    })
}

export function check_alipay_orderNum(data) {
    return request({
        url: 'user/order/check_alipay_orderNum',
        method: "post",
        data
    })
}


export function handlePullFailedFeedback(data) {
    return request({
        url: 'user/notify_pullError',
        method: "post",
        data
    })
}